<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('batch_score_entry')"
                    @filter-div-status="datatable.filterStatus=$event"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('batch_score_entry')"
                    @filter-div-status="datatable.filterStatus=$event"></HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <ValidationObserver ref="formValidate">
                    <b-row>
                        <b-col cols="6" xs="12">
                            <b-row>
                                <b-col cols="12">
                                    <ValidationProvider name="faculty_code" rules="" v-slot="{errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox v-model="formData.faculty_code"
                                                               :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="program_code" rules="" v-slot="{errors}">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox v-model="formData.program_code"
                                                               :is_faculty_code_required="true"
                                                               :faculty_code="formData.faculty_code"
                                                               :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="class" rules="" v-slot="{errors}">
                                        <b-form-group :label="$t('class')">
                                            <classes-selectbox v-model="formData.class"
                                                               :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="course_code" rules="required" v-slot="{errors}">
                                        <b-form-group :label="$t('course_code')">
                                            <course-auto-complete v-model="formData.course_code"
                                                                  resultType="code"
                                                                  :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="course_name" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_name')">
                                            <b-form-input v-model="formData.course_name"
                                                          :placeholder="$t('course_name')"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="section" :rules="formData.student_numbers == null || formData.student_numbers == '' ? 'required' : ''" v-slot="{errors}">
                                        <b-form-group :label="$t('section')">
                                            <b-form-input type="number"
                                                          min="1"
                                                          v-check-min-max-value
                                                          v-model="formData.section"
                                                          :placeholder="$t('section')"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="6" xs="12">
                            <b-row>
                                <b-col cols="12">
                                    <ValidationProvider name="student_numbers" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('students')">
                                            <lined-textarea v-model="formData.student_numbers"
                                                            :nowrap="false"
                                                            :disabled="false"
                                                            :styles="{ height: '29em', resize: 'both' }"
                                                            :validateError="errors[0]">
                                            </lined-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <div v-if="showTable">
                <b-table
                    show-empty
                    :empty-filtered-text="$t('no_result')"
                    :empty-text="$t('no_result')"
                    bordered
                    striped
                    responsive
                    :items="students"
                    :fields="studentFields"
                    class="mb-0 table-dropdown no-scrollbar border rounded">
                    <template #cell(name)="row">
                        {{row.item.name+' '+row.item.surname}}
                    </template>
                    <template #cell(exams)="row">
                        <b-form-select
                            @input="handleInput(row.item.id, $event)"
                            :value="row.item.instructors_letter_grade"
                            :options="grades(row.item.grades)"
                        ></b-form-select>
                    </template>
                    <template #cell(note)="row">
                        <b-button
                            class="rounded-circle border-0 bg-light"
                            :class="[row.item.instructors_note === null ? 'text-secondary' : 'text-primary']"
                            @click="handleInfo(row.item)"
                            style="width: 30px; height: 30px"
                            :title="$t('add_description')">
                            <b-icon icon="info-circle"></b-icon>
                        </b-button>
                    </template>
                </b-table>
                <div class="d-flex mt-4">
                    <b-button variant="primary" class="mr-2" @click="save">{{ $t("save") }}</b-button>
                </div>
            </div>
        </app-layout>
        <b-modal ref="noteModal" id="noteModal" size="md" centered :title="$t('explanation')">
            <template>
                <b-form-textarea
                    v-model="student_note"
                    id="textarea"
                    :placeholder="$t('note')"
                    rows="3"
                    max-rows="6"
                    @input="handleInfoKeyup($event)"
                ></b-form-textarea>
            </template>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="handleInfoSave()">
                        {{ $t("save") }}
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";

import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import ClassesSelectbox from "@/components/interactive-fields/ClassesSelectbox"
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete"
import LinedTextarea from "@/components/elements/LinedTextarea"

import {ValidationObserver, ValidationProvider} from "vee-validate"
import StudentCourseService from "@/services/StudentCourseService";
import qs from "qs";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,

        FacultySelectbox,
        ProgramSelectbox,
        ClassesSelectbox,
        CourseAutoComplete,
        LinedTextarea,

        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('batch_score_entry')
        }
    },
    data() {
        return {
            datatable: {
                filterStatus: true
            },

            formData: {
                faculty_code: null,
                program_code: null,
                class: null,
                course_code: null,
                course_name: null,
                section: null,
                student_numbers: ''
            },

            showTable: false,
            student: {},
            student_note: null,
            students: [],
            studentFields: [
                {
                    key: "student_number",
                    label: this.toUpperCase("student_number"),
                    sortable: true,
                },
                {
                    key: "name",
                    label: this.toUpperCase("name_surname"),
                },
                {
                    key: "class",
                    label: this.toUpperCase("class"),
                },
                {
                    key: "exams",
                    label: this.$t("grade"),
                    sortable: false,
                    thClass: 'width-100'
                },
                {
                    key: "note",
                    label: '',
                    sortable: false,
                }
            ],
        }
    },
    methods: {
        async filter() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.showTable = true
                this.getRows()
            }
        },

        filterClear() {
            this.formData = {
                faculty_code: null,
                program_code: null,
                class: null,
                course_code: null,
                course_name: null,
                section: null,
                student_numbers: ''
            }
            this.students = []
            this.showTable = false
            this.$refs.formValidate.reset()
        },

        getRows() {
            if(this.formData.student_numbers != ''){
                let studentNumbers = this.formData.student_numbers.split(/\n/)
                this.formData.student_number = []
                studentNumbers.forEach((itm, index) => {
                    this.formData.student_number[index] = itm
                })
                delete this.formData.student_numbers
            }

            let config = {
                params: {
                    filter: this.formData,
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            StudentCourseService.getStudentAffairsEntry(config).then(response => {
                this.students = response.data.data
            }).catch(e => {
                if (e.status == '406') {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                }
                else {
                    if (e.data.errors['filter.course_code']) {
                        this.$refs.formValidate.errors.course_code.push(e.data.errors['filter.course_code'][0]);
                    }
                    if (e.data.errors['filter.section']) {
                        this.$refs.formValidate.errors.section.push(e.data.errors['filter.section'][0]);
                    }
                }
            })
        },

        grades(opitons) {
            let data = [];
            opitons.map((item) => {
                data.push({value: item, text: item});
            });
            let opitonData = [{value: null, text: this.$t("select")}, ...data];
            return opitonData;
        },

        handleInput(id, value) {
            this.students.forEach((itm, index) => {
                if(itm.id == id){
                    this.students[index].instructors_letter_grade = value
                }
            })
        },

        save(){
            if(this.checkPermission('studentcourse_studentaffairsupdateentry')){
                this.$swal
                    .fire({
                        text: this.$t('batch_score_entry_info'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('view_score_again')
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            let formData = []
                            this.students.forEach(itm => {
                                if(itm.instructors_letter_grade != null && itm.instructors_letter_grade != ''){
                                    formData.push({
                                        student_course_id: itm.id,
                                        instructors_letter_grade: itm.instructors_letter_grade,
                                        instructors_note: itm.instructors_note
                                    })
                                }
                            })

                            let data = {
                                grades: formData
                            }

                            StudentCourseService.updateStudentAffairsEntry(data).then(response => {
                                this.$toast.success(this.$t('api.'+response.data.message))
                                this.filterClear()
                            }).catch(e => {
                                this.$toast.error(this.$t('api.'+e.data.message))
                            })
                        }
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        handleInfo(student) {
            this.student = student;
            this.student_note = student.instructors_note;
            this.$refs.noteModal.show()
        },

        handleInfoKeyup(value) {
            this.student_note = value;
        },

        handleInfoSave() {
            this.students.forEach((itm, index) => {
                if(itm.id == this.student.id){
                    this.students[index].instructors_note = this.student_note
                }
            })
            this.$refs.noteModal.hide()
        }
    }
};
</script>

